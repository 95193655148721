import React, { useContext } from "react";
import LinkButton from "../components/link-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ShopContext, LineItem } from "../context/shop-context-provider";
import Section from "../components/section";
import { Container, Box, Grid, Typography, Paper, IconButton, useTheme, Theme, Hidden } from "@material-ui/core";

interface CheckoutBlockProps {

}

const LineItemRow: React.FC<LineItem> = (props) => {
    const theme = useTheme<Theme>();
    const { updateCart, removeFromCart } = useContext(ShopContext);

    const variantImage = props.variant.image ? (
        <img
            src={props.variant.image.src}
            alt={`${props.title} product shot`}
            height="80px"
        />
    ) : null;

    return <Box py={2}>
        <Grid spacing={1} container justify="center" alignItems="center">
            <Grid item container xs={4} sm={2} justify="center">
                {variantImage}
            </Grid>
            <Grid item container xs={8} sm={3} direction="column">
                <Typography component="div" variant="h6">
                    {props.title}
                </Typography>
                {props.variant.title && props.variant.title != "Default Title" &&
                    <Typography component="div" variant="body1">
                        {props.variant.title}
                    </Typography>
                }
            </Grid>
            <Grid item container xs={12} sm={2} justify="center" alignItems="center">
                <IconButton onClick={() => updateCart(props.id, props.quantity - 1)}>
                    <Typography variant="srOnly">Decrease Quantity</Typography>
                    <FontAwesomeIcon icon={faMinus} color={theme.palette.error.main} size="xs" />
                </IconButton>
                <Typography variant="h6" component="span">{props.quantity}</Typography>
                <IconButton onClick={() => updateCart(props.id, props.quantity + 1)}>
                    <Typography variant="srOnly">Increase Quantity</Typography>
                    <FontAwesomeIcon icon={faPlus} color={theme.palette.primary.main} size="xs" />
                </IconButton>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Typography align="right" variant="body1" component="div">
                    <Hidden smUp>
                        Unit Price:&nbsp;&nbsp;
                    </Hidden>
                    ${props.variant.price}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Typography align="right" variant="body1" component="div">
                    <Hidden smUp>
                        Total Price:&nbsp;&nbsp;
                    </Hidden>
                    ${(props.variant.price * props.quantity).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item container xs={12} sm={1} justify="center">
                <IconButton onClick={() => removeFromCart(props.id)}>
                    <Typography variant="srOnly">Remove Item</Typography>
                    <FontAwesomeIcon icon={faTrash} color={theme.palette.grey} size="xs" />
                </IconButton>
            </Grid>
        </Grid>
        <Box pt={2} borderBottom={1} color={theme.palette.secondary.main} />
    </Box>;
}


const CheckoutBlock: React.FC<CheckoutBlockProps> = (props) => {
    const { checkout, checkoutUrl } = useContext(ShopContext);

    var total = checkout.lineItems.reduce((sum, item) => sum + (item.quantity * item.variant.price), 0).toFixed(2);

    return <>
        <Section>
            <Container>
                <Typography variant="h2" component="h1" gutterBottom>Shopping Cart</Typography>
                <Paper>
                    <Box minHeight={300} p={4}>
                        <Hidden xsDown>
                            <Grid container>
                                <Grid item sm={5}>
                                    <Typography align="center" variant="h5" gutterBottom>Description</Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography align="center" variant="h5">Quantity</Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography align="right" variant="h5">Unit Price</Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography align="right" variant="h5">Total Price</Typography>
                                </Grid>
                                <Grid item sm={1}>
                                    &nbsp;
                                </Grid>
                            </Grid>
                        </Hidden>
                        {!checkoutUrl &&
                            <Box p={4}>
                                <Typography variant="h2" align="center">Loading Cart...</Typography>
                            </Box>
                        }
                        {checkoutUrl && checkout.lineItems && checkout.lineItems.length == 0 &&
                            <Box p={4}>
                                <Typography variant="h2" align="center">Your cart is empty.</Typography>
                            </Box>
                        }
                        {checkout.lineItems.map(l => <LineItemRow {...l} key={l.id} />)}
                    </Box>
                    <Box px={4} pb={4} textAlign="right">
                        <Typography align="right" variant="body1">Subtotal: ${total}</Typography>
                        <Typography align="right" variant="body2">Taxes: (calculated in checkout)</Typography>
                        <Typography align="right" variant="body2">Shipping: (calculated in checkout)</Typography>
                        <Box pt={2}>
                            <LinkButton forceSameTab url={checkoutUrl ?? ''} disabled={checkout.lineItems.length == 0} color="primary" variant="contained" size="large">Checkout</LinkButton>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Section>
    </>;
}

export default CheckoutBlock;