import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import MainLayout from "../layouts/mainLayout";
import SEO from "../components/seo";
import CheckoutBlock from "../composed/checkout-block";


interface LocationProps {
    location: Location;
}

const CartPage: React.FC<LocationProps> = (props) => {

    const data = useStaticQuery(graphql`
        query {
            seo: shopJson(block: {eq: "seo" }) {
                title
                description
                image {
                  childImageSharp {
                      resize(width: 750, height: 300, cropFocus: ENTROPY) {
                          src
                          width
                          height
                      }
                  }
                }
            }
        } 
    `);

    return <MainLayout>
        <SEO {...data.seo} location={props.location} />

        <CheckoutBlock />

    </MainLayout>;

}

export default CartPage;